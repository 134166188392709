"use strict";

//performance.mark("vendors initialization");

var $ = require("jquery");
require("./bootstrap");
require("jquery.cycle2");
var Forms = require("./components/forms");
var Popup = require("./components/popup");

$(document).ready(function() {
  new Forms();
  new Popup();

  $(document).on("click", "dl.content-accordion dt", function() {
    $(this)
      .next("dd")
      .slideToggle();
    $(this).toggleClass("active");
  });

  (function() {
    var config = {
      kitId: "hel8msx",
      scriptTimeout: 3000
    };
    var h = document.getElementsByTagName("html")[0];
    h.className += " wf-loading";
    var t = setTimeout(function() {
      h.className = h.className.replace(/(\s|^)wf-loading(\s|$)/g, " ");
      h.className += " wf-inactive";
    }, config.scriptTimeout);
    var tk = document.createElement("script"),
      d = false;
    tk.src = "//use.typekit.net/" + config.kitId + ".js";
    tk.type = "text/javascript";
    tk.async = "true";
    tk.onload = tk.onreadystatechange = function() {
      var a = this.readyState;
      if (d || (a && a != "complete" && a != "loaded")) return;
      d = true;
      clearTimeout(t);
      try {
        Typekit.load(config);
      } catch (b) {}
    };
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(tk, s);
  })();

  $(".slider").cycle({
    fx: "scrollHorz",
    timeout: 6000,
    slides: "div.slide",
    prev: "#slide-prev",
    next: "#slide-next"
    //pager: '#pager',
  });

  //Scrolling Sidebar
  var $sidebar = $("#body .sidebar"),
    $window = $(window),
    offset = $sidebar.offset(),
    topPadding = 15,
    maxHeight = $("#body .content").height(),
    sideHeight = $sidebar.height();

  $window.scroll(function() {
    //Only scroll on screens > 767 (ie. before responsive layout collapses)
    if ($(window).width() > 767) {
      var scroll = $window.scrollTop() - offset.top + topPadding,
        limit = maxHeight - sideHeight;

      if ($window.scrollTop() > offset.top) {
        if (scroll > limit) {
          //Prevent sidebar from pushing the footer off the page
          $sidebar.stop().animate({
            marginTop: limit
          });
        } else {
          $sidebar.stop().animate({
            marginTop: scroll
          });
        }
      } else {
        $sidebar.stop().animate({
          marginTop: 0
        });
      }
    }
  });

  $("#map-container").each(function() {
    var mapId = $(this).attr("id");
    var map;

    var southyarra = new google.maps.LatLng(-37.83899, 144.993639);

    //var brighton = new google.maps.LatLng(-37.913114,144.993922);

    var LatLngList = new Array();
    var MY_MAPTYPE_ID = mapId;

    function initialize() {
      var featureOpts = [];
      var mapOptions = {
        zoom: 12,
        center: southyarra,
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        overviewMapControl: true,
        mapTypeId: MY_MAPTYPE_ID
      };

      map = new google.maps.Map(document.getElementById(mapId), mapOptions);

      var styledMapOptions = {
        name: "Custom Style"
      };
      var customMapType = new google.maps.StyledMapType(
        featureOpts,
        styledMapOptions
      );

      var syloc = new google.maps.Marker(
        {
          clickable: true,
          position: southyarra,
          map: map,
          title: "SY Group - South Yarra"
        },
        LatLngList.push(southyarra)
      );
      //var brloc = new google.maps.Marker(
      //    {
      //        clickable: true,
      //        position: brighton,
      //        map: map,
      //        title: 'SY Group - Brighton'
      //    },
      //    LatLngList.push(brighton)
      //);

      var syinfo = new google.maps.InfoWindow({
        content:
          "<h4>SY Group</h4><p>Level 3, 199 Toorak Road<br />SOUTH YARRA VIC 3141 Australia</p><p>Phone: (03) 9827 8888<br />Fax: (03) 9827 9999</p>"
      });
      //var brinfo = new google.maps.InfoWindow({
      //    content: '<h4>SY Group</h4><p>Level 1, 31 Church Street<br />BRIGHTON VIC 3186 Australia</p><p>Phone: (03) 9827 8888<br />Fax: (03) 9827 9999</p>'
      //});

      //var bounds = new google.maps.LatLngBounds ();

      //for (var i = 0, LtLgLen = LatLngList.length; i < LtLgLen; i++) {
      //    bounds.extend (LatLngList[i]);
      //}
      //map.fitBounds (bounds);

      map.mapTypes.set(MY_MAPTYPE_ID, customMapType);

      google.maps.event.addListener(syloc, "click", function() {
        syinfo.open(map, syloc);
      });
      //google.maps.event.addListener(brloc, 'click', function() {
      //    brinfo.open(map,brloc);
      //});

      syinfo.open(map, syloc);
      //brinfo.open(map,brloc);
    }

    google.maps.event.addDomListener(window, "load", initialize);
  });

  // accordion

  $(".toggle").on("click", ".toggle-title", function() {
    if (
      $(this)
        .closest(".toggle")
        .hasClass("active")
    ) {
      $(this)
        .closest(".toggle")
        .removeClass("active")
        .siblings()
        .removeClass("active");
      $(this)
        .closest(".toggle")
        .find(".toggle-title")
        .addClass("active")
        .find("i")
        .html("+");
    } else {
      $(this)
        .closest(".toggle")
        .addClass("active")
        .siblings()
        .removeClass("active");
      $(this)
        .closest(".toggle")
        .find(".toggle-title")
        .removeClass("active")
        .find("i")
        .html("–");
      $(this)
        .closest(".toggle")
        .siblings()
        .find("i")
        .html("+");
    }
    $(this)
      .closest(".toggle")
      .find(".toggle-inner")
      .slideToggle();
    $(this)
      .closest(".toggle")
      .siblings()
      .find(".toggle-inner")
      .slideUp();
  });

  // upload file

  var inputs = document.querySelectorAll(".inputfile");
  Array.prototype.forEach.call(inputs, function(input) {
    var label = input.nextElementSibling,
      labelVal = label.innerHTML;

    input.addEventListener("change", function(e) {
      var fileName = "";
      if (this.files && this.files.length > 1)
        fileName = (this.getAttribute("data-multiple-caption") || "").replace(
          "{count}",
          this.files.length
        );
      else fileName = e.target.value.split("\\").pop();

      if (fileName) label.querySelector("span").innerHTML = fileName;
      else label.innerHTML = labelVal;
    });

    // Firefox bug fix
    input.addEventListener("focus", function() {
      input.classList.add("has-focus");
    });
    input.addEventListener("blur", function() {
      input.classList.remove("has-focus");
    });
  });
});
