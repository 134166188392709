'use strict';

var formsClass = 'pbz_form';

var $ = require('jquery');
var swal = require('sweetalert2');
require('../../../../../node_modules/jquery.maskedinput/src/jquery.maskedinput');

module.exports = function() {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    /**
     * Сбор данных из формы
     *
     * @param options
     * @returns {*}
     */
    $.fn.formData = function(options) {
        if (this === undefined) {
            return ('error');
        }

        var settings = $.extend({
            'select_attr': 'name',
            'textvalue': false,
            'validator': false,
            'invalid': false,
            'valid': false,
            'callback': false
        }, options);


        var send_obj = {};

        var put = function(key, val, title, obj) {
            if (key.indexOf('[]') > -1 && key[key.length - 1] === ']' && key[key.length - 2] === '[') {
                key = key.replace('[]', '');

                if (send_obj[key] !== undefined && Object.prototype.toString.call(send_obj[key]) === '[object Array]') {
                    send_obj[key].push({val: val, title: title, obj: obj});
                } else {
                    send_obj[key] = [{val: val, title: title, obj: obj}];
                }
            } else {
                send_obj[key] = {val: val, title: title, obj: obj};
            }
            obj.rules = getValidateRules(obj);

            if (settings.validator === false)
                settings.validator = {};

            for (var rule in obj.rules) {
                if (typeof settings.validator[key] == 'undefined')
                    settings.validator[key] = [];
                settings.validator[key][settings.validator[key].length] = rule;
            }
        };

        var getValidateRules = function(obj) {
            var data = obj.data(),
                rules = {},
                dataKey;
            for (dataKey in data) {
                if (dataKey.indexOf('validate') === 0) {
                    rules[dataKey.replace('validate', '').toLowerCase()] = data[dataKey];
                }
            }

            return rules;
        };

        // get text/hidden input and textarea
        var txt = $('textarea:enabled, input[type=hidden]:enabled, input[type!=checkbox][type!=radio]:enabled', this);

        $.each(txt, function(i, j) {
            if ($(j).attr(settings.select_attr) !== undefined && $(j).not(':disabled')) {
                put($(j).attr(settings.select_attr), $(j).val(), $(j).data('title'), $(j));
            }
        });


        // get select
        var select = $('select:enabled', this);

        $.each(select, function(i, j) {
            if ($(j).attr(settings.select_attr) !== undefined && $(j).not(':disabled')) {
                if (settings.textvalue === true) {
                    put($(j).attr(settings.select_attr), $(j).children('option:selected').text(), $(j).data('title'), $(j));
                } else {
                    put($(j).attr(settings.select_attr), $(j).val(), $(j).data('title'), $(j));
                }
            }
        });


        // get checkboxes
        var checkBox = $('input[type=checkbox]:enabled');

        // TODO реализовать множественный выбор
        $.each(checkBox, function(i, j) {
            put($(j).attr('name'), $(j).attr('value'), $(j).data('title'), $(j));
        });

        // get radiobuttons
        var radio = $('input[type=radio]:checked:enabled');

        $.each(radio, function(i, j) {
            put($(j).attr('name'), $(j).attr('value'), $(j).data('title'), $(j));
        });

        // validation  ==============================================
        var checkValid = function(val, rule) {
            var re;

            switch (rule) {
                case 'required':
                    return !(val === undefined || val.length === 0);

                case 'email':
                    re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(val);

                // case 'phone':
                //   re = /^\+?[-\(\)\d\s]{5,19}$/;
                //   return re.test(val);
                //
                // case 'uaphone':
                //   re = /\+38 \(0[0-9]{2}\) [0-9]{3}-[0-9]{2}-[0-9]{2}/g;
                //   return re.test(val);
                //
                // case 'ruphone':
                //   re = /\+7 \(0[0-9]{2}\) [0-9]{3}-[0-9]{2}-[0-9]{2}/g;
                //   return re.test(val);

                case 'url':
                    re = /^(https?:\/\/)?[a-z0-9~_\-\.]+\.[a-z]{2,9}(\/|:|\?[!-~]*)*?$/;
                    return re.test(val);

                case 'alpha':
                    re = /^[a-zA-Z]+$/;
                    return re.test(val);

                case 'alpha_num':
                    re = /^[a-zA-Z0-9]+$/;
                    return re.test(val);

                case 'alpha_dash':
                    re = /^[-_a-zA-Z0-9]+$/;
                    return re.test(val);
            }

            if (rule.indexOf(':') > -1) {
                rule = rule.split(':');

                switch (rule[0]) {
                    case 'len':
                        return rule.slice(1, rule.length).some(function(el) {
                            return val.length == el;
                        });

                    case 'range_len':
                        return (val.length >= rule[1] && val.length <= rule[2]);

                    case 'max':
                        return val <= rule[1];

                    case 'min':
                        return val >= rule[1];

                    case 'range':
                        return (val >= rule[1] && val <= rule[2]);

                    case 'not':
                        return val != rule[1];

                    case 'is':
                        return rule.slice(1, rule.length).some(function(el) {
                            return val == send_obj[el];
                        });

                    case 'same':
                        return val == send_obj[rule[1]];

                    case 'diff':
                        return val != send_obj[rule[1]];
                }
            }

            return true;
        };

        var inValidEl = {};

        if (settings.validator) {
            for (var k in settings.validator) {
                for (var i = 0; i < settings.validator[k].length; i++) {
                    if (!checkValid(send_obj[k].val, settings.validator[k][i])) {
                        if (inValidEl[k] === undefined)
                            inValidEl[k] = [];
                        inValidEl[k].push(settings.validator[k][i].split(':')[0]);
                    }
                }
            }
        }

        if (!$.isEmptyObject(inValidEl) && settings.invalid) {
            settings.invalid({'errors': inValidEl, obj: send_obj});
            return false;
        } else if (settings.valid) {
            settings.valid();
        }

        // callback function =========================================
        if (settings.callback) {
            inValidEl = $.isEmptyObject(inValidEl) || inValidEl;
            settings.callback(send_obj, inValidEl);
        }

        return send_obj;
    };

    /**
     * Валидация
     *
     * @param options
     */
    $.fn.validateTooltip = function(options) {
        var $this = $(this);
        var top = parseInt($this.offset().top - 34, 10);
        var left = parseInt($this.offset().left + $this.outerWidth() / 2, 10);

        $this.tooltip = $('<div class="validate-error" style="top:' + top + 'px; left:' + left + 'px">' +
            '<div class="animated"><div><i></i>' + options.text + '</div></div>' +
            '</div>');

        $('body').append($this.tooltip);

        $this.tooltip.find('.animated').addClass('shake');

        $this.click(function() {
            $this.tooltip.remove();
        });
    };

    /**
     * Отправка формы
     */
    $(document).on('submit', 'form.' + formsClass, function(e) {
        e.preventDefault();
        var form = $(this);
        $('.validate-error').remove();
        if (!form.hasClass('disabled')) {
            var data = form.formData({
                validator: {},
                invalid: function(data) {
                    for (var name in data.errors) {
                        data.obj[name].obj.validateTooltip({
                            text: data.obj[name].obj.rules[data.errors[name][0]]
                        });
                    }
                }
            });

            var files = {};
            var isset_files = false;

            if (data === false) {
                return false;
            } else {
                for (var el in data) {
                    if (typeof data[el].obj !== 'undefined' && data[el].obj[0].type == 'file') {
                        isset_files = true;
                        files[el] = data[el];
                        delete data[el];
                    } else
                        delete data[el].obj;
                }
            }

            var fd = new FormData();

            if (isset_files) {
                for (var el in files) {
                    var field = files[el].obj.get(0);
                    for (var i = 0; i < field.files.length; i++) {
                        fd.append(el, field.files[i]);
                    }
                }
            }

            fd.append('data', JSON.stringify(data));

            var url = form.attr('action') || '/send_ajax';
            $.ajax({
                url: url,
                type: 'POST',
                processData: false,
                contentType: false,
                // data: {data: JSON.stringify(data)},
                data: fd,
                dataType: 'json',
                beforeSend: function() {
                    form.addClass('disabled');
                    form.find('button[type="submit"]').html('Sending...');
                },
                success: function(response) {
                    form.trigger('reset');
                    form.find('button[type="submit"]').html('Sent');

                    if (typeof response.status !== 'undefined' && response.status == 'success') {
                        form.trigger('sent', response);
                        if (typeof form.data('success-title') !== 'undefined' || typeof  form.data('success-message') !== 'undefined') {
                            swal(form.data('success-title'), form.data('success-message'), 'success');
                        }
                        if (typeof form.data('success-redirect') !== 'undefined') {
                            window.location = form.data('success-redirect');
                        }
                    } else {
                        form.trigger('error', response);
                        if (typeof form.data('error-title') !== 'undefined' || typeof form.data('error-message') !== 'undefined') {
                            swal(form.data('error-title'), form.data('error-message'), 'error');
                        }
                    }
                },
                error: function(response) {
                    form.removeClass('disabled');
                    form.find('button[type="submit"]').html('Error');
                    form.trigger('error', response);
                    if (typeof form.data('error-title') !== 'undefined' || typeof form.data('error-message') !== 'undefined') {
                        swal(form.data('error-title'), form.data('error-message'), 'error');
                    }
                }
            });
        }

        return true;
    });

    /**
     * Удаление сообщений валидатора
     */
    $(document).on('click', function() {
        $('body > .validate-error').remove();
    });

    /**
     * Маска телефона
     */
    $('form.' + formsClass + ' input[name="phone"]').each(function() {
        var $this = $(this);
        if (typeof $this.data('validate-phone') !== 'undefined')
            $this.mask('+99 (999) 999-9999');
    });
};
